import { getStates, updateGlobal } from '../store';
import * as apiClient from '../api-client';
// eslint-disable-next-line import/no-cycle
import * as globalActions from './global';

import { reader, writer } from '../helpers/action';
import { changelogFetcher } from './changelog';

const entity = 'subscription';

export const getSubscription = reader(entity, apiClient.getTenantSubscription);

export const updateSubscription = writer(
  entity,
  async (subscriptionSettings) => {
    const { data } = await apiClient.updateSubscription(subscriptionSettings);
    const { global: { tenantSettings } } = getStates();
    updateGlobal({ tenantSettings: { ...tenantSettings, ...data } });
    globalActions.getUserDefinedEnums({ bustCache: true });
    return data;
  },
);

export const getHistory = changelogFetcher(entity, 'Subscription');

export const getSubscriptionUsage = reader(
  `${entity} usage`,
  apiClient.getSubscriptionUsage,
);
